import { useState } from "react";
import { settingItem, TSetting } from "../helper";
import Form from "./Form";
import { Button } from "primereact/button";
import { dynamicHomeService } from "../../../../../api/services/dynamic-home";
import { toast } from "src/utils/toast";
import { THomeSettingItem } from "../../../../../api/types/dynamic-home";

export type TaskType = { id: number; title: string };
export const MIN_COUNT = 3;
export const MAX_COUNT = 20;
type Props = {
  setting: TSetting;
};
const RelocationData = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const [websiteSetting, setWebsiteSetting] = useState<settingItem[]>(
    props.setting.websiteSetting,
  );
  const [appSetting, setAppSetting] = useState<settingItem[]>(
    props.setting.appSetting,
  );

  const isValid = (setting: THomeSettingItem[]) => {
    const hasNotValidItem = setting.find(
      (item) => item.count < MIN_COUNT || item.count > MAX_COUNT,
    );
    if (hasNotValidItem) {
      toast.error(`Count must be in range of ${MIN_COUNT} and ${MAX_COUNT}`);
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const readyWebsiteSetting = websiteSetting.map((item) => ({
      key: item.key,
      order: item.order,
      count: item.count,
    }));

    const readyAppSetting = appSetting.map((item) => ({
      key: item.key,
      order: item.order,
      count: item.count,
    }));
    const sections = [...readyWebsiteSetting, ...readyAppSetting];
    if (isValid(sections)) {
      const payload = {
        sections,
      };
      setLoading(true);
      await dynamicHomeService.updateSetting(payload);
      setLoading(false);
      toast.success("Config saved successfully!");
    }
  };

  return (
    <div className="flex justify-content-start">
      <div>
        <div className="flex mb-4">
          <Form setting={appSetting} setSetting={setAppSetting} title="App" />

          <div
            style={{
              width: 32,
            }}></div>
          <Form
            setting={websiteSetting}
            setSetting={setWebsiteSetting}
            title="Website"
          />
        </div>
        <div className="flex ">
          <Button
            label={"Save"}
            className="p-button-primary "
            type="button"
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default RelocationData;
