import { Button } from "primereact/button";
import { useState } from "react";
import { toast } from "src/utils/toast";
import { dynamicHomeService } from "src/api/services/dynamic-home";
import Form from "./Form";
import { TOrderedStarItem } from "src/types/dynamic-home";
import { Typography } from "@mui/material";

export type TaskType = { id: number; title: string };
export type TPayloadKey =
  | "jobs"
  | "packages"
  | "freelancers"
  | "categories"
  | "proFreelancers";

type Props = {
  orderedJobs: TOrderedStarItem[];
  notDeletedItems: TOrderedStarItem[];
  changeOrder: (jobId: number, newOrder: number) => void;
  removeFromOrderedJob: (jobId: number) => void;
  updateOrderedItemsUrl: string;
  payloadKey: TPayloadKey;
  name: string;
};
const RelocationData = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const {
    orderedJobs,
    changeOrder,
    removeFromOrderedJob,
    updateOrderedItemsUrl,
    payloadKey,
    name,
    notDeletedItems,
  } = props;

  const onSubmit = async () => {
    setLoading(true);
    let order = 0;
    const payload = {
      [payloadKey]: orderedJobs.map((item) => {
        if (item.order > 0) {
          order = order + 1;
          return {
            id: item.id,
            order: order,
          };
        }
        return {
          id: item.id,
          order: item.order,
        };
      }),
    };
    await dynamicHomeService.updateOrderedJobs(updateOrderedItemsUrl, payload);
    setLoading(false);
    toast.success("Config saved successfully!");
  };
  return (
    <div className="flex justify-content-start">
      <div>
        <div className="flex mb-4 pt-4">
          {orderedJobs.length > 0 ? (
            <Form
              items={notDeletedItems}
              changeOrder={changeOrder}
              title={`You can relocate ${name} with drag and drop`}
              removeFromOrderedJob={removeFromOrderedJob}
            />
          ) : (
            <Typography variant="h6">
              No {name} selected. The system will automatically display {name}{" "}
              based on the backend logic. Select {name} to override this
              behavior
            </Typography>
          )}
        </div>
        <div className="flex ">
          <Button
            label={"Save"}
            className="p-button-primary "
            type="button"
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default RelocationData;
