import { useState, useMemo } from "react";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { dynamicHomeService } from "../../../../../api/services/dynamic-home";
import { getItemsInArray1ButNotInArray2 } from "../../../../../utils/getItemsNotInArray2";
import { TOrderedStarItem } from "src/types/dynamic-home";
import debounce from "lodash/debounce";

// Define the type for the option object
interface OptionType {
  value: string;
  label: string;
}

type Props = {
  addToOrderedJob: (jobId: number, jobTitle: string) => void;
  orderedJobs: TOrderedStarItem[];
  searchUrl: string;
  getTitle: (item: any, isInSearch: boolean) => string;
  name: string;
};

const ApiSelectComponent = (props: Props) => {
  const { orderedJobs, addToOrderedJob, searchUrl, getTitle, name } = props;
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<OptionType> | null>(null);

  const fetchOptions = async (inputValue: string): Promise<OptionType[]> => {
    if (!inputValue) return [];

    try {
      const response = await dynamicHomeService.getItems(searchUrl, inputValue);
      const fetchedList = response.data.payload.items;
      const notIdenticalItems = getItemsInArray1ButNotInArray2(
        fetchedList,
        orderedJobs,
      );
      return notIdenticalItems.map((job) => ({
        value: String(job.id),
        label: getTitle(job, true),
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  // Use useMemo to create a debounced version of fetchOptions
  const debouncedFetchOptions = useMemo(
    () => debounce(fetchOptions, 300),
    [searchUrl, orderedJobs, getTitle],
  );

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(null);
    if (option && option.value) {
      addToOrderedJob(Number(option.value), option.label);
    }
  };

  return (
    <div>
      <AsyncSelect
        loadOptions={debouncedFetchOptions}
        defaultOptions
        onChange={handleChange}
        placeholder={`Type to search in ${name}`}
        value={selectedOption}
      />
    </div>
  );
};

export default ApiSelectComponent;
