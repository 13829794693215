import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const getChannelAvatar = (
  channel: SendBird.GroupChannel,
  currentUserId: string,
) => {
  if (!channel.members.length) {
    return "images/default-avatar.png";
  }
  const others: SendBird.Member[] = [...channel.members];
  const ind = others.findIndex((member) => member.userId === currentUserId);
  others.splice(ind, 1);
  return !!others[1]
    ? others[1].profileUrl || "images/default-avatar.png"
    : others[0]?.profileUrl || "images/default-avatar.png";
};

export const getChannelTitle = (
  channel: SendBird.GroupChannel,
  currentUserId: string,
) => {
  if (channel.url && channel.url.includes("_DIRECT")) {
    const others: SendBird.Member[] = [...channel.members];
    const ind = others.findIndex((member) => member.userId === currentUserId);
    others.splice(ind, 1);
    return !!others[1]
      ? others[1].nickname.toLowerCase().includes("admin")
        ? others[0].nickname
        : others[1].nickname
      : channel.name; // If the other user in the chat does not exist (e.g., they deleted their account), display the channel name as the title.
  }
  return channel.name;
};

export const getLastMessageDate = (channel: SendBird.GroupChannel) => {
  return dayjs(channel.lastMessage?.createdAt).fromNow();
};
