import { IBaseUser } from "./user";
import { ICategory } from "./category";
import { AdminActionHistory } from "../../pages/Dashboard/proFreelancerManagement/ProFreelancerDetail/AdminActionsHistory";

export enum PRO_FREELANCER_REQUEST_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REVOKE = "revoke",
}

interface IFreelancerUser extends IBaseUser {
  packageCount: number;
  serviceCount: number;
  rateAverage: number;
}

export interface IProFreelancerRequestListItem {
  createdAt: string;
  cvMediaId: number;
  id: number;
  idCardBackMedia: { id: number; url: string };
  idCardFrontMedia: { id: number; url: string };
  serviceCategories: ICategory[];
  status: PRO_FREELANCER_REQUEST_STATUS;
  userId: number;
  updatedAt: string;
  user: IFreelancerUser;
  flows: {
    approveOrRejectedDate: string;
  }[];
}

interface IFile {
  url: string;
  originalName: string;
  type: string;
}

interface IImage {
  id: number;
  url: string;
}

export interface IProFreelancerRequestDetail {
  cvFile?: IFile;
  portfolioFiles: IFile[];
  proFreelancer: {
    id: number;
    user: IBaseUser;

    passportMedia?: IImage;
    idCardBackMedia?: IImage;
    idCardFrontMedia?: IImage;
    serviceCategories: ICategory[];
    status: PRO_FREELANCER_REQUEST_STATUS;
    linkedInProfile?: string;
    website?: string;
    portfolioSite?: string;
  };
  flows: AdminActionHistory[];
}
