import React from "react";
import "./styles.css";
import Header from "src/components/App/PageHeader";
import GeneralDetails from "src/pages/Dashboard/userManagement/components/GeneralDetails";
import Languages from "src/pages/Dashboard/userManagement/components/Languages";
import { useCustomerDetails } from "../../CustomerDetailsContext";
import UserDetailsTransactionsAndContracts from "src/components/App/UserDetailsTransactionsAndContracts";
import { customerProfileTabs } from "../../../constants/index";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import UserChats from "src/components/App/UserChats";

const Details: React.FC = () => {
  const location = useLocation();
  const currentTab = location.pathname.split("/");
  const navigate = useNavigate();

  const { userData, setUserData } = useCustomerDetails();
  const handleChangeTab = (selectedTab: string) => {
    if (selectedTab === "jobs")
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.JOBS.GET_BY_DATA(userData!.id)
          .ABSOLUTE,
      );
    else if (selectedTab === "profile") {
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.DETAILS.GET_BY_DATA(userData!.id)
          .ABSOLUTE,
      );
    }
  };
  return userData ? (
    <>
      <Header
        hasGoBack={true}
        title={`${userData.firstName} ${userData.lastName}`}
        tabs={customerProfileTabs}
        currentTab={currentTab[currentTab.length - 1]}
        updateTab={(selectedTab) => handleChangeTab(selectedTab)}
      />
      <div className="grid">
        <div className="sm:col-12 p-3">
          <GeneralDetails userData={userData} setUserData={setUserData} />
          <Languages userData={userData} />
        </div>
      </div>
      <UserDetailsTransactionsAndContracts
        userType="customer"
        userId={userData.id}
      />
      <div className="mb-3"></div>
      <UserChats userId={`${userData.id}`} />
    </>
  ) : (
    <span>Loading...</span>
  );
};

export default Details;
