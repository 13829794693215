import React, { useCallback, useEffect, useState } from "react";
import { chatService } from "src/api/services/chat";
import { Channel, ChannelList, SendBirdProvider } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import { IUserInfoInfoInSendBird } from "../../../api/types/chat";
import "./style.css";
import { ChatAppId } from "src/constants/send-bird-app-id";
import { Fieldset } from "primereact/fieldset";
import { SearchInput } from "src/components/Kit/SearchInput";
import { ChatPreviewItem } from "./ChannelPreview";
import {
  getChannelAvatar,
  getChannelTitle,
  getLastMessageDate,
} from "./helper";
type Props = {
  userId: string;
};

const UserChats = (props: Props) => {
  const { userId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [channelInfo, setChannelInfo] = useState<IUserInfoInfoInSendBird>();
  const [selectedChannel, setSelectedChannel] = useState<any>();
  const [searchTerm, setSearchTerm] = useState("");

  const fetchChannelInfo = useCallback(async () => {
    if (userId) {
      setLoading(true);
      try {
        const result = await chatService.getChannelInfo(userId);
        setChannelInfo(result.data.payload);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [userId]);

  useEffect(() => {
    fetchChannelInfo();
  }, [fetchChannelInfo]);

  if (!channelInfo) return null;
  return (
    <Fieldset legend="Chats">
      <div style={{ marginTop: 24 }}>
        {loading && <div>Loading..</div>}
        <SendBirdProvider
          appId={ChatAppId}
          userId={channelInfo.user_id}
          accessToken={channelInfo.access_token}
          config={{
            logLevel: "error",
          }}>
          <div style={{ display: "flex", height: 500, gap: "8px" }}>
            <div className="hide-add-group-button">
              <ChannelList
                onChannelSelect={(channel) => {
                  setSelectedChannel(channel);
                }}
                queries={{
                  channelListQuery: {
                    // channelNameContainsFilter: searchTerm,
                    nicknameContainsFilter: searchTerm,
                  },
                }}
                disableAutoSelect={true}
                renderHeader={() => {
                  return (
                    <div>
                      <SearchInput
                        value={searchTerm}
                        onValueChange={(value) => setSearchTerm(value)}
                        placeholder="Search in users"
                      />
                    </div>
                  );
                }}
                renderChannelPreview={({ channel }) => {
                  return (
                    <div>
                      <ChatPreviewItem
                        name={getChannelTitle(channel, channelInfo.user_id)}
                        profileImage={getChannelAvatar(
                          channel,
                          channelInfo.user_id,
                        )}
                        // lastMessage={channel.lastMessage?.name || ""}
                        lastMessage={""}
                        lastMessageDate={getLastMessageDate(channel)}
                      />
                    </div>
                  );
                }}
              />
            </div>
            {!!selectedChannel && (
              <Channel
                channelUrl={selectedChannel.url}
                renderMessageInput={() => {
                  return null;
                }}
                useReaction={false}
                useMessageGrouping={false}
              />
            )}
          </div>
        </SendBirdProvider>
      </div>
    </Fieldset>
  );
};

export default UserChats;
