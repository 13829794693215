import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { IUserInfoInfoInSendBird } from "../types/chat";

export const chatService = {
  getChannelInfo(userId: string) {
    return AxiosInstance.get<IAxiosResponse<IUserInfoInfoInSendBird>>(
      API_ROUTES.CHAT.GET_CHANNEL_INFO(userId),
    );
  },
};
