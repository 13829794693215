import {
  Description,
  FolderZip,
  Image as ImageIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { proFreelancerService } from "../../../../api/services/pro-freelancer";
import {
  IProFreelancerRequestDetail,
  PRO_FREELANCER_REQUEST_STATUS,
} from "../../../../api/types/pro-freelancer";
import ReceiptCard from "../../../../components/Kit/ReceiptCard";
import AdminRejectAndApproveActions from "./AdminRejectAndApproveActions";
import AdminActionsHistory from "./AdminActionsHistory";
import { downLoadFile, getFiles, getImages, getPersonalData } from "./helper";
import Separator from "./Separator";
import AdminRevokeActions from "./AdminRevokeActions";

const ProFreelancerDetails = () => {
  const getFileIcon = (type: string) => {
    switch (type) {
      case "pdf":
        return <Description color="error" />;
      case "image":
        return <ImageIcon color="primary" />;
      case "zip":
        return <FolderZip color="secondary" />;
      default:
        return <Description />;
    }
  };
  const routeParams = useParams<{ id: string }>();
  const [proFreelancerRequestDetail, setProFreelancerRequestDetail] =
    useState<IProFreelancerRequestDetail>();

  const fetchContractDetails = useCallback(async () => {
    if (routeParams.id) {
      try {
        Promise.all([proFreelancerService.getDetails(routeParams.id)])
          .then((responses) => {
            setProFreelancerRequestDetail(responses[0].data.payload);
          })
          .catch((error) => {
            throw new Error(error);
          });
        // setLoading(true);
      } catch (error) {
        console.error(error);
      } finally {
        // setLoading(false);
      }
    }
  }, [routeParams.id]);

  useEffect(() => {
    fetchContractDetails();
  }, [fetchContractDetails]);

  const canDoActions = () => {
    if (
      proFreelancerRequestDetail?.proFreelancer.status ===
      PRO_FREELANCER_REQUEST_STATUS.PENDING
    )
      return true;
    return false;
  };

  const canBeRevoked = () => {
    if (
      proFreelancerRequestDetail?.proFreelancer.status ===
      PRO_FREELANCER_REQUEST_STATUS.APPROVED
    )
      return true;
    return false;
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h3" textAlign="center" gutterBottom>
        Pro Freelancer Request
      </Typography>
      <Divider sx={{ mb: 4 }} />

      <Grid container spacing={4} sx={{ mb: 4 }}>
        {/* Personal Information Section */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Personal Information
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {proFreelancerRequestDetail &&
                getPersonalData(proFreelancerRequestDetail).map((item, key) => (
                  <Box key={key} sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {item.label}:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.value}
                    </Typography>
                  </Box>
                ))}
            </CardContent>
          </Card>
        </Grid>

        {/* Application Files Section */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Application Files
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                {proFreelancerRequestDetail &&
                  getFiles(proFreelancerRequestDetail).map((file, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Card
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          boxShadow: 2,
                          p: 1,
                          transition: "transform 0.2s, box-shadow 0.2s",
                          "&:hover": {
                            transform: "scale(1.05)",
                            boxShadow: 4,
                            backgroundColor: "#f5f5f5",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() =>
                          window.open(
                            downLoadFile(file.url, file.name),
                            "_blank",
                          )
                        }>
                        <Avatar sx={{ mr: 2 }}>{getFileIcon(file.type)}</Avatar>
                        <Box>
                          <Typography variant="body2">{file.name}</Typography>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box display="flex" gap={3}>
        {proFreelancerRequestDetail &&
          getImages(proFreelancerRequestDetail).map((imgItem, index) => {
            return (
              <ReceiptCard
                key={index}
                title={imgItem.name}
                imageUrl={imgItem.url}
              />
            );
          })}
      </Box>
      {/* Action Buttons */}
      <Box display="flex" justifyContent="center" gap={3} mt={4}>
        {routeParams.id && canDoActions() ? (
          <AdminRejectAndApproveActions requestId={routeParams.id} />
        ) : null}
        {routeParams.id && canBeRevoked() ? (
          <AdminRevokeActions requestId={routeParams.id} />
        ) : null}
      </Box>

      {proFreelancerRequestDetail?.flows?.length === 1 ? null : (
        <>
          <Separator />
          <Card
            elevation={4}
            sx={{
              maxWidth: 500,
              margin: 2,
              padding: 2,
              borderRadius: 2,
              backgroundColor: "#fdfdfd",
            }}>
            {proFreelancerRequestDetail?.flows
              .filter(
                (_, index) =>
                  index + 1 !== proFreelancerRequestDetail?.flows.length,
              )
              .map((data, index) => (
                <AdminActionsHistory
                  key={index}
                  {...data}
                  isLastItem={index === 0}
                />
              ))}
          </Card>
        </>
      )}
    </Box>
  );
};

export default ProFreelancerDetails;
