import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";

interface ChatPreviewItemProps {
  name: string;
  profileImage?: string;
  lastMessage: string;
  lastMessageDate: string;
}

export const ChatPreviewItem: React.FC<ChatPreviewItemProps> = ({
  name,
  profileImage,
  lastMessage,
  lastMessageDate,
}) => {
  return (
    //@ts-ignore
    <ListItem
      button
      alignItems="flex-start"
      sx={{
        px: 2,
        py: 1.5,
        "&:hover": { backgroundColor: "rgba(0,0,0,0.04)" },
        "&.Mui-selected": {
          backgroundColor: "rgba(25, 118, 210, 0.08)",
          "&:hover": { backgroundColor: "rgba(25, 118, 210, 0.12)" },
        },
      }}>
      <ListItemAvatar>
        <Avatar src={profileImage}>
          {!profileImage && name.charAt(0).toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight={500}>{name}</Typography>
            <Typography variant="caption" color="text.secondary">
              {lastMessageDate}
            </Typography>
          </Box>
        }
        secondary={
          <Typography
            variant="body2"
            color="text.secondary"
            noWrap
            sx={{ maxWidth: "100%" }}>
            {lastMessage}
          </Typography>
        }
      />
    </ListItem>
  );
};
