import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  IPaginatedRequestBody,
  PaginatedResponse,
  IAxiosResponse,
} from "../types/axios-response";
import {
  IContract,
  IAvailableActionResponse,
  IChangeStateBody,
  IChannelInfo,
  IContractStatusHistory,
  IUploadReceiptImageBody,
} from "../types/contracts";
import {
  IProFreelancerRequestListItem,
  IProFreelancerRequestDetail,
} from "../types/pro-freelancer";
import { IMinimumPrice } from "../types/minimum-price";

export const proFreelancerService = {
  getAll(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<PaginatedResponse<IProFreelancerRequestListItem>>(
      API_ROUTES.PRO_FREELANCERS.ROOT,
      data || {},
    );
  },

  getDetails(id: string) {
    return AxiosInstance.get<IAxiosResponse<IProFreelancerRequestDetail>>(
      API_ROUTES.PRO_FREELANCERS.DETAIL(id),
    );
  },
  approve(id: string) {
    return AxiosInstance.post<IAxiosResponse<IMinimumPrice>>(
      API_ROUTES.PRO_FREELANCERS.APPROVE(id),
    );
  },

  reject(id: string, rejectionReason: string) {
    return AxiosInstance.post<IAxiosResponse<IMinimumPrice>>(
      API_ROUTES.PRO_FREELANCERS.REJECT(id),
      { reason: rejectionReason },
    );
  },

  revoke(id: string, rejectionReason: string) {
    return AxiosInstance.deleteByBody<IAxiosResponse<IMinimumPrice>>(
      API_ROUTES.PRO_FREELANCERS.Revoke(id),
      { reason: rejectionReason },
    );
  },
};
