import React from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "src/components/App/PageHeader";
import GeneralDetails from "src/pages/Dashboard/userManagement/components/GeneralDetails";
import FinancialAccount from "src/pages/Dashboard/userManagement/components/FinancialAccount";
import Skills from "src/pages/Dashboard/userManagement/components/Skills";
import Languages from "src/pages/Dashboard/userManagement/components/Languages";
import Educations from "src/pages/Dashboard/userManagement/components/Educations";
import Experiences from "src/pages/Dashboard/userManagement/components/Experiences";
import { useFreelancerDetails } from "../../FreelancerDetailsContext";
import { freelancerProfileTabs } from "src/pages/Dashboard/userManagement/Freelancers/constants";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import UserDetailsTransactionsAndContracts from "src/components/App/UserDetailsTransactionsAndContracts";
import UserChats from "src/components/App/UserChats";

const Details: React.FC = () => {
  const location = useLocation();
  const currentTab = location.pathname.split("/");
  const navigate = useNavigate();
  const { userData, setUserData } = useFreelancerDetails();

  const handleChangeTab = (selectedTab: string) => {
    if (selectedTab !== currentTab[currentTab.length - 1])
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.SERVICES.GET_BY_DATA(userData!.id)
          .ABSOLUTE,
      );
  };

  return userData ? (
    <>
      <Header
        hasGoBack={true}
        title={`${userData.firstName} ${userData.lastName}`}
        tabs={freelancerProfileTabs}
        currentTab={currentTab[currentTab.length - 1]}
        updateTab={(selectedTab) => handleChangeTab(selectedTab)}
      />
      <div>
        <GeneralDetails userData={userData} setUserData={setUserData} />
      </div>
      <div className="grid">
        <div className="col">
          <Experiences userData={userData} />
        </div>
        <div className="col">
          <Educations userData={userData} />
        </div>
      </div>
      <Skills userData={userData} />
      <div className="grid">
        <div className="col">
          <Languages userData={userData} />
        </div>
        <div className="col">
          <FinancialAccount fAccount={userData?.financialAccounts[0]} />
        </div>
      </div>
      <UserDetailsTransactionsAndContracts
        userType="freelancer"
        userId={userData.id}
      />
      <div className="mb-3"></div>
      <UserChats userId={`${userData.id}`} />
    </>
  ) : (
    <span>Loading...</span>
  );
};

export default Details;
