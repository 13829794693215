/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from "react";
import "./styles.css";
import { IGeneralDetailsProps, IUpdateBaseUserFormData } from "./types";
import CustomCard from "src/components/Kit/CustomCard";
import { staticImages } from "src/assets";
import HighlightText from "src/components/App/HighlightText";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Button } from "primereact/button";
import { freelancerService } from "src/api/services/freelancer";
import { InputSwitch } from "primereact/inputswitch";
import { userService } from "src/api/services/user";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import Avatar from "src/components/App/Avatar";
import { toast } from "src/utils/toast";
import Card from "src/components/Kit/Card";
import CardBody from "src/components/Kit/Card/CardBody";
import { Divider } from "primereact/divider";
import CardTitle from "src/components/Kit/Card/CardTitle";
import { Message } from "primereact/message";
import { baseService } from "src/api/services/base";
import ProIcon from "src/components/Kit/ProIcon";
import { Box } from "@mui/material";

interface IDropDownOption {
  label: string;
  value: number | undefined;
}

const GeneralDetails: React.FC<IGeneralDetailsProps> = ({ userData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [, setToggleLoading] = useState<boolean>(false);
  const [mediaId, setMediaId] = useState<number | undefined>(undefined);
  const [isActive, setIsActive] = useState<boolean>(
    userData!.status === "Active",
  );
  const [countries, setCountries] = useState<IDropDownOption[]>([]);
  const [cities, setCities] = useState<IDropDownOption[]>([]);
  const [selectedCountry, setSelectedCountry] = useState(
    userData!.city?.country?.id,
  );
  const [selectedCity, setSelectedCity] = useState(userData!.city?.id);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IUpdateBaseUserFormData>({
    defaultValues: {
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      cityId: userData?.city?.id,
      birthDate: userData?.birthDate
        ? new Date(userData?.birthDate)
        : new Date(),
      bio: userData?.bio || "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        firstName: string().required("First name is required"),
        lastName: string().required("Last name is required"),
        bio: string()
          .required("Bio is required")
          .max(800, "Maximum length of bio is 800 characters"),
      }),
    ),
  });

  const onFormSubmit = async (data: IUpdateBaseUserFormData) => {
    let tempData: IUpdateBaseUserFormData = { ...data };
    tempData = {
      firstName: tempData.firstName,
      lastName: tempData.lastName,
      birthDate: tempData.birthDate,
      bio: tempData.bio,
    };
    console.log(tempData.bio?.length);
    if (mediaId) tempData.mediaId = mediaId;
    if (selectedCity) tempData["cityId"] = selectedCity;

    try {
      setLoading(true);
      await freelancerService.updateDetails(userData!.id, tempData);
      setLoading(false);
      toast.success("General details updated successfully!");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onAvatarChanged = (id: number) => setMediaId(id);

  const handleToggleUser = () => {
    confirmDialog({
      message: `Are you sure you want to ${
        userData!.status === "Active" ? "deactivate" : "activate"
      } ${userData!.email}`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          setToggleLoading(true);
          await userService.changeActivation(userData!.id, !isActive);
          setIsActive((prevState) => !prevState);
        } catch (err) {
          console.log(err);
        } finally {
          setToggleLoading(false);
        }
      },
    });
  };

  const getListOfCountries = async () => {
    let tempCountries: IDropDownOption[] = [];

    try {
      let res = await baseService.getCountries();
      res.data.payload.items.forEach((country) => {
        tempCountries.push({
          label: country.title,
          value: country.id,
        });
      });
      setCountries(tempCountries);
    } catch (error) {
      console.log(error);
    }
  };

  const getListOfCities = async (id: number) => {
    let tempCities: IDropDownOption[] = [];
    try {
      let res = await baseService.getCities(id, true);
      res.data.payload.items.forEach((city) => {
        tempCities.push({
          label: city.title,
          value: city.id,
        });
      });

      setCities(tempCities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListOfCountries();
    if (userData?.city) {
      getListOfCities(userData?.city?.country?.id);
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) getListOfCities(selectedCountry);
  }, [selectedCountry]);

  return (
    <CustomCard>
      <div>
        <Card>
          <CardBody>
            <div className="flex align-items-center flex-column">
              <Avatar
                src={userData!.avatar?.url || staticImages.sampleUser}
                editable
                onAvatarChanged={onAvatarChanged}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}>
                <span>{`${userData?.firstName} ${userData?.lastName}`}</span>
                {userData?.proFreelancerStatus === "approved" ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                      borderRadius: "8px",
                      bgcolor: "#0094FF",
                    }}>
                    <ProIcon />
                  </Box>
                ) : null}
              </Box>
              <span className="text-color-secondary">{userData?.email}</span>
            </div>
            <Divider />
            <div className="flex flex-column">
              <div className="flex flex-row justify-content-between align-items-center mt-2">
                <span className="general-details-title">Email status</span>
                <HighlightText
                  style={{ marginLeft: "10px" }}
                  boolean={userData!.emailVerified}>
                  <span style={{ fontSize: "15px" }}>
                    {userData!.emailVerified ? "Verified" : "Not Verified"}
                  </span>
                </HighlightText>
              </div>
              <div className="flex flex-row justify-content-between mt-3">
                <span className="general-details-title">Role</span>
                <span className="ml-3">{userData!.role || "-"}</span>
              </div>
              <div className="flex flex-row justify-content-between align-items-center mt-3">
                <span className="general-details-title">Status</span>
                <InputSwitch
                  checked={isActive}
                  className={`ml-3`}
                  onChange={handleToggleUser}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardTitle>Personal Information</CardTitle>
          <CardBody>
            <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
              <div className="grid">
                <div className="col">
                  <FormFieldWrapper>
                    <label
                      htmlFor="firstName"
                      className={classNames({ "p-error": errors.firstName })}>
                      First name
                    </label>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          autoFocus
                          type="text"
                          {...field}
                          id="firstName"
                          className={classNames("block w-full", {
                            "p-invalid": !!errors.firstName,
                          })}
                        />
                      )}
                    />
                  </FormFieldWrapper>
                </div>
                <div className="col">
                  <FormFieldWrapper>
                    <label
                      htmlFor="lastName"
                      className={classNames({ "p-error": errors.lastName })}>
                      Last name
                    </label>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          autoFocus
                          type="text"
                          {...field}
                          id="email"
                          className={classNames("block w-full", {
                            "p-invalid": !!errors.lastName,
                          })}
                        />
                      )}
                    />
                  </FormFieldWrapper>
                </div>
                <div className="col">
                  <FormFieldWrapper>
                    <label
                      htmlFor="birthDate"
                      className={classNames({ "p-error": errors.birthDate })}>
                      Birth date
                    </label>
                    <Controller
                      name="birthDate"
                      control={control}
                      render={({ field }) => (
                        <Calendar
                          style={{ width: "100%" }}
                          id="birthDate"
                          {...field}
                        />
                      )}
                    />
                  </FormFieldWrapper>
                </div>
              </div>
              {userData!.role === "freelancer" && (
                <div className="grid">
                  <div className="col">
                    <FormFieldWrapper>
                      <label
                        htmlFor="country"
                        className={classNames({ "p-error": errors.country })}>
                        Country
                      </label>
                      <Dropdown
                        value={selectedCountry}
                        options={countries}
                        onChange={(e) => setSelectedCountry(e.value)}
                        style={{ width: "100%" }}
                      />
                    </FormFieldWrapper>
                  </div>
                  <div className="col">
                    <FormFieldWrapper>
                      <label htmlFor="city">City</label>
                      <Dropdown
                        value={selectedCity}
                        options={cities}
                        onChange={(e) => setSelectedCity(e.value)}
                        style={{ width: "100%" }}
                      />
                    </FormFieldWrapper>
                  </div>
                </div>
              )}
              <FormFieldWrapper>
                <label
                  htmlFor="bio"
                  className={classNames({ "p-error": errors.bio })}>
                  Bio
                </label>
                <Controller
                  name="bio"
                  control={control}
                  render={({ field }) => (
                    <InputTextarea
                      autoFocus
                      {...field}
                      id="email"
                      className={classNames("block w-full", {
                        "p-invalid": !!errors.bio,
                      })}
                    />
                  )}
                />
                {errors.bio && (
                  <Message severity="error" text={errors.bio.message} />
                )}
              </FormFieldWrapper>
              <FormFooterActions>
                <Button
                  label="Submit"
                  className="p-button-primary"
                  type="submit"
                  loading={loading}
                />
              </FormFooterActions>
            </form>
          </CardBody>
        </Card>
        <ConfirmDialog />
      </div>
    </CustomCard>
  );
};

export default GeneralDetails;
