import React from "react";

type Props = {
  fill?: string;
  borderColor?: string;
  tickColor?: string;
  className?: string;
};

const ProIcon = (props: Props) => {
  const {
    fill = "none",
    borderColor = "#FEFEFE",
    tickColor = "#FEFEFE",
    className = "",
  } = props;
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85583 15.5475H5.78833C5.32667 15.5475 4.9525 15.1733 4.9525 14.7117V12.6433C4.9525 12.4217 4.86417 12.2092 4.7075 12.0525L3.245 10.59C2.91833 10.2633 2.91833 9.73417 3.245 9.40833L4.7225 7.93083C4.87 7.78333 4.9525 7.58417 4.9525 7.37583V5.28833C4.9525 4.82667 5.32667 4.4525 5.78833 4.4525H7.85667C8.07833 4.4525 8.29083 4.36417 8.4475 4.2075L9.91 2.745C10.2367 2.41833 10.7658 2.41833 11.0917 2.745L12.5542 4.2075C12.7108 4.36417 12.9233 4.4525 13.145 4.4525H15.2133C15.675 4.4525 16.0492 4.82667 16.0492 5.28833V7.35667C16.0492 7.57833 16.1375 7.79083 16.2942 7.9475L17.7567 9.41C18.0833 9.73667 18.0833 10.2658 17.7567 10.5917L16.2942 12.0542C16.1375 12.2108 16.0492 12.4233 16.0492 12.645V14.7133C16.0492 15.175 15.675 15.5492 15.2133 15.5492H13.145C12.9233 15.5492 12.7108 15.6375 12.5542 15.7942L11.0917 17.2567C10.765 17.5833 10.2358 17.5833 9.91 17.2567L8.4475 15.7942C8.29 15.635 8.0775 15.5475 7.85583 15.5475Z"
        stroke={borderColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 8.75L9.875 11.875L8 10"
        stroke={tickColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProIcon;
