import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { contractService } from "src/api/services/contract";
import { Fieldset, FieldsetToggleParams } from "primereact/fieldset";
import { IContractStatusHistory } from "src/api/types/contracts";
import styles from "./ContractStatusHistory.module.css";
import dayjs from "dayjs";

const ContractStatusHistory: React.FC = () => {
  const routeParams = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [statusHistoryItems, setStatusHistoryItems] = useState<
    IContractStatusHistory[]
  >([]);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const fetchStatusHistory = useCallback(async () => {
    if (routeParams.id) {
      setLoading(true);
      try {
        const result = await contractService.getStatusHistory(routeParams.id);
        setStatusHistoryItems(result.data.payload.items);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [routeParams.id]);

  useEffect(() => {
    if (!isCollapsed && statusHistoryItems.length === 0) {
      fetchStatusHistory();
    }
  }, [isCollapsed, statusHistoryItems, fetchStatusHistory]);

  const onItemExpanded = (e: FieldsetToggleParams) => {
    console.log(e);
    setIsCollapsed(e.value);
  };

  return (
    <Fieldset
      legend="Status History"
      toggleable={true}
      collapsed={isCollapsed}
      onToggle={onItemExpanded}>
      {loading && <div>Loading...</div>}
      {statusHistoryItems.map((item) => (
        <div className={styles["contract-status-history"]} key={item.id}>
          <div>
            <b>Status:</b> {item.status}
          </div>
          <div>
            <b>Date:</b> {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
          </div>
          <div>
            <b>Comment:</b> {item.comment || "---"}
          </div>
        </div>
      ))}
    </Fieldset>
  );
};

export default ContractStatusHistory;
