import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  IAxiosResponse,
  IPaginatedRequestBody,
  PaginatedResponse,
} from "../types/axios-response";
import { ICategory, INewCategoryData } from "../types/category";
import { IPartner, INewPartnerData } from "../types/partner";

export const partnersService = {
  getAll(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<PaginatedResponse<IPartner>>(
      API_ROUTES.PARTNERS.ROOT,
      data || {},
    );
  },
  addNew(data?: INewPartnerData) {
    console.log("data", data);
    if (!data?.description) {
      delete data?.description;
    }
    if (!data?.website) {
      delete data?.website;
    }
    return AxiosInstance.post<IAxiosResponse<IPartner>>(
      API_ROUTES.PARTNERS.ADD_NEW,
      data,
    );
  },
  update(categoryId: number, data?: INewCategoryData) {
    return AxiosInstance.put<IAxiosResponse<ICategory>>(
      API_ROUTES.PARTNERS.UPDATE(categoryId),
      data || {},
    );
  },
  changeActivation(id: number, active: boolean) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.PARTNERS.CHANGE_ACTIVATION(id),
      {
        active,
      },
    );
  },
};
