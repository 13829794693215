import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import { CheckCircle, Warning, Cancel } from "@mui/icons-material";
import { proFreelancerService } from "../../../../api/services/pro-freelancer";
import { toast } from "src/utils/toast";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useNavigate } from "react-router-dom";

type Props = {
  requestId: string;
};

const AdminRevokeActions = (props: Props) => {
  const { requestId } = props;
  const [openConfirmApproveDialog, setOpenConfirmApproveDialog] =
    useState(false);
  const [openConfirmRejectDialog, setOpenConfirmRejectDialog] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const navigate = useNavigate();

  const handleApproveClick = () => {
    setOpenConfirmApproveDialog(true);
  };

  const handleRejectClick = () => {
    setOpenConfirmRejectDialog(true);
  };

  const handleConfirmApprove = async () => {
    setLoadingApprove(true);

    await proFreelancerService.approve(requestId);
    setLoadingApprove(false);
    setOpenConfirmApproveDialog(false);
    toast.success("Changes saved successfully!");
    navigate(ROUTE_CONSTANTS.DASHBOARD.PRO_FREELANCERS.ROOT.ABSOLUTE);
  };

  const handleConfirmReject = async () => {
    if (!rejectionReason) {
      toast.error("Please provide a reason for revoking.");
      return;
    }
    if (rejectionReason.length < 15) {
      toast.error("Length of reason cannot be less than 15");
      return;
    }

    setLoadingReject(true);
    await proFreelancerService.revoke(requestId, rejectionReason);
    setLoadingReject(false);
    setOpenConfirmRejectDialog(false);
    toast.success("Changes saved successfully!");
    navigate(ROUTE_CONSTANTS.DASHBOARD.PRO_FREELANCERS.ROOT.ABSOLUTE);
  };

  return (
    <>
      {/* Reject Button */}
      <Button
        variant="outlined"
        color="error"
        onClick={handleRejectClick}
        startIcon={
          loadingReject ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <Cancel />
          )
        }
        sx={{
          px: 4,
          py: 1.5,
          borderRadius: 3,
          fontWeight: "bold",
          mr: 2,
        }}
        disabled={loadingReject}>
        {loadingReject ? "Revoking..." : "Revoke"}
      </Button>

      {/* Confirm revoke Dialog */}
      <Dialog
        open={openConfirmRejectDialog}
        onClose={() => setOpenConfirmRejectDialog(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { borderRadius: 6, padding: 4 },
        }}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "1.8rem",
            textAlign: "center",
            pb: 2,
          }}>
          <Cancel sx={{ color: "red", fontSize: 30 }} /> Revoke Confirmation
        </DialogTitle>
        <DialogContent
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}>
          <Typography
            variant="body1"
            sx={{ color: "text.secondary", fontSize: "1rem", mb: 3 }}>
            Are you sure you want to revoke this freelancer? This action cannot
            be undone.
          </Typography>
          <Typography variant="body2" sx={{ color: "text.primary", mb: 2 }}>
            Please provide a reason for revoking this freelancer.:
          </Typography>
          <textarea
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder="Enter reason for revoking"
            maxLength={300}
            rows={4}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "8px",
              border: "1px solid #ddd",
              fontSize: "1rem",
              resize: "none",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              fontSize: "0.875rem",
              marginTop: "8px",
            }}>
            {rejectionReason.length}/300
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: 2,
            mt: 3,
          }}>
          <Button
            onClick={() => setOpenConfirmRejectDialog(false)}
            variant="outlined"
            color="secondary"
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 3,
              fontWeight: "bold",
            }}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmReject}
            variant="contained"
            color="error"
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 3,
              fontWeight: "bold",
            }}
            disabled={loadingReject}>
            {loadingReject ? "Revoking..." : "Confirm Revoke"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminRevokeActions;
